<template>
    <div class="px-4">
        <audio-player
            ref="audioPlayer"
            :audio-list="audioList.map(elm => elm.url)"
            :isLoop="false"
            @loadedmetadata="loadedmetadata"
            theme-color="white"
            :show-prev-button="audio ? false : true"
            :show-next-button="audio ? false : true"
        >
            <template #play-prev>
                <favorito></favorito>
            </template>
            <template #play-next>
                <descargar></descargar>
            </template>
        </audio-player>
    </div>
</template>

<script>
import AudioPlayer from '@liripeng/vue-audio-player'
import Descargar from './Descargar.vue';
import Favorito from './Favorito.vue';
export default {
    components: { Descargar, Favorito, AudioPlayer },
    props: {
        audio: String,
        default: null
    },
    data:() => ({
        audioList: []
    }),
    methods: {
        loadedmetadata() {
            if (this.$refs.audioPlayer) {
                this.$refs.audioPlayer.play()
            }
        }
    },
    mounted() {
        this.audioList.push({
            name: 'audio1',
            url: `/mp3/podcast/${this.$store.state.player.archivo}`
        })
    },
    beforeDestroy() {
        if (this.$refs.audioPlayer) {
            this.$refs.audioPlayer.pause()
        }
    }
}
</script>

<style lang="scss" scoped>
::v-deep .audio-player {
    .audio__duration, .audio__current-time {
        font-size: 15px;
        color: white;
    }
    .audio__progress {
        background-color: var(--v-secondary-base) !important;
    }
    .audio__progress-wrap {
        height: 10px;
        background: rgba(255, 255, 255, .5);
    }
    .audio__progress-point {
        width: 20px;
        height: 20px;
        margin-top: -10px;
        background-color: var(--v-secondary-base) !important;
    }
    .audio__play-volume-icon-wrap .audio__play-volume-wrap {
        background-color: gray;
    }
    .audio__play-rate__dropdown {
        color: black;
    }
}
</style>