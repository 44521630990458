<template>
    <v-app :style="fondo">
        <v-navigation-drawer app v-model="drawer" color="rgba(0,51,102,.8)" v-if="$store.state.isAuthenticated">
            <template v-slot:prepend>
                <v-list-item two-line>
                    <v-list-item-avatar color="white" class="elevation-10">
                        <v-img :src="$store.state.user.avatar"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ $store.state.user.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ $store.state.user.email }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </template>

            <div class="pa-3">
                <foto></foto>

                <!-- <v-switch inset color="secondary" label="Tema oscuro" v-model="$vuetify.theme.dark"></v-switch> -->
            </div>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block color="white" outlined rounded @click="logout()">Cerrar sesion</v-btn>
                </div>
            </template>
        </v-navigation-drawer>
        <v-app-bar app flat extension-height="90" v-if="$store.state.isAuthenticated" color="primary">
            <v-img src="@/assets/logo-horizontal-lineas.png" width="150" contain class="shrink"></v-img>
            <v-spacer></v-spacer>

            <v-icon color="success" v-if="$store.state.pendientes.length == 0">mdi-checkbox-marked</v-icon>
            <v-menu offset-y v-else>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon large color="white" v-bind="attrs" v-on="on">
                        <v-badge :content="$store.state.pendientes.length" :value="$store.state.pendientes.length" color="red" overlap>
                            <v-icon>mdi-bell</v-icon>
                        </v-badge>
                    </v-btn>
                </template>
                <v-list dense color="secondary">
                    <v-list-item dense v-for="material in $store.state.pendientes" :key="material.id"
                        :to="{ name: 'cuestionario', params: { tipo: 'evaluacion', id: material.id } }">
                        <v-list-item-title>{{ material.nombre }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <!-- <v-btn fab x-small color="white" exact :to="{ name: 'favoritos' }">
                <v-icon color="grey">mdi-heart-outline</v-icon>
            </v-btn> -->
            <v-btn icon large color="white" exact :to="{ name: 'favoritos' }">
                <v-icon>mdi-heart</v-icon>
            </v-btn>
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            <!-- <template #extension  v-if="mostrarPuntaje">
                <v-container>
                    <v-row align="center">
                        <v-col class="text-center text-uppercase font-weight-medium">{{ $store.state.user.name }}</v-col>
                        <v-col cols="auto">
                            <v-avatar class="elevation-10" color="white" size="80">
                                <v-img :src="$store.state.user.avatar"></v-img>
                            </v-avatar>
                        </v-col>
                        <v-col class="text-center font-weight-bold">{{ $store.state.user.puntos }} <br>puntos</v-col>
                    </v-row>
                </v-container>
            </template> -->
        </v-app-bar>

        <v-main>
            <v-container v-if="$store.state.isAuthenticated && mostrarPuntaje">
                <v-row align="center">
                    <v-col cols="auto">
                        <v-avatar class="elevation-10" color="white" size="80">
                            <v-img :src="$store.state.user.avatar"></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col class="text-center text-uppercase font-weight-medium">
                        {{ $store.state.user.name }}
                    </v-col>
                    <v-col cols="auto" class="text-center font-weight-bold">{{ $store.state.user.puntos }} <br>puntos</v-col>
                </v-row>
            </v-container>

            <transition name="slide-y-reverse-transition" mode="out-in">
                <router-view />
            </transition>

            <player class="mt-auto" style="z-index: 1;" v-if="$store.state.mini != null && !$store.state.consentimiento">
            </player>

            <v-fade-transition>
                <v-img src="@/assets/ayuda.png" width="150" contain @click="soporte()" class="soporte" v-if="$store.state.isAuthenticated && mostrarSoporte"></v-img>
            </v-fade-transition>
        </v-main>

        <v-footer app color="primary" dark class="justify-space-between" id="footer" v-if="$store.state.isAuthenticated">
            <!-- <v-btn icon x-large exact :to="{ name: 'home' }">
                <v-icon>mdi-home-variant-outline</v-icon>
            </v-btn> -->

            <v-btn icon x-large exact :to="{ name: 'home' }">
                <v-icon>mdi-home-variant-outline</v-icon>
            </v-btn>

            <v-btn icon x-large exact
                :to="{ name: 'cuestionario', params: { tipo: 'juego', id: $store.state.cuestionario.id } }"
                v-if="$store.state.cuestionario">
                <v-icon>mdi-gamepad-variant-outline</v-icon>
            </v-btn>
            <v-btn icon x-large exact disabled v-else>
                <v-icon>mdi-gamepad-variant-outline</v-icon>
            </v-btn>

            <v-btn icon x-large exact :to="{ name: 'estadisticas' }">
                <v-icon>mdi-chart-line</v-icon>
            </v-btn>
            <v-btn icon x-large exact :to="{ name: 'posiciones' }">
                <v-icon>mdi-trophy-outline</v-icon>
            </v-btn>
            <v-btn icon x-large exact :to="{ name: 'descargas' }">
                <v-icon>mdi-folder-download-outline</v-icon>
            </v-btn>
        </v-footer>
    </v-app>
</template>

<script>
import Foto from './components/Foto.vue';
import Player from './components/Player.vue';

export default {
    components: { Player, Foto },
    data() {
        return {
            fab: false,
            drawer: false,
            mostrarPuntaje: true,
            mostrarSoporte: true,
            fondo: {
                backgroundImage: "url('/img/APP-Pod-exeltis_imagen-1.webp')",
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                backgroundPosition: 'center bottom',
            },
            rules: [
                value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ]
        }
    },
    watch: {
        $route(to, from) {
            if (['material'].includes(to.name)) {
                this.mostrarPuntaje = false
            } else {
                this.mostrarPuntaje = true
            }

            if (['login', 'registro', 'welcome', 'instalar'].includes(to.name)) {
                this.fondo.backgroundImage = "url('/img/APP-Pod-exeltis_imagen-1.webp')"
                this.fondo.backgroundPosition = "center bottom"
            } else {
                this.fondo.backgroundImage = "url('/img/espotyexeltis-fondo.jpg')"
                this.fondo.backgroundPosition = "top left"
            }
            
            if (['estadisticas', 'posiciones', 'cuestionario', 'descargas'].includes(to.name)) {
                this.mostrarSoporte = false
            } else {
                this.mostrarSoporte = true
            }
        }
    },
    methods: {
        async logout() {
            const response = await this.$store.dispatch('logout')

            if (response.success) {
                console.log("logout exitoso");
                this.drawer = false
                this.$router.push({ name: "login" });
            } else {
                console.log("Error en el logout");
            }
        },
        soporte() {
            window.open("https://wa.me/+525619921719?text=Hola%2C%20necesito%20ayuda%20con%20la%20aplicaci%C3%B3n%20de%20PodExeltis", "_blank");
        }
    },
    async created() {
        const token = localStorage.getItem('token');
        if (token && token != 'undefined') {
            console.log(token);
            this.$store.commit('SET_TOKEN', token);

            const response = await this.$store.dispatch('getUser', token)

            if (response.success && this.$route.name != 'home') {
                console.log("token exitoso");
                this.$router.push({ name: 'home' });
            } else {
                console.log("Error en el token");
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.soporte {
    position: fixed;
    bottom: 70px;
    right: 10px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}
</style>