import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#003366',
                secondary: '#00cccc',
            },
            dark: {
                primary: '#003366',
                secondary: '#00cccc',
            }
        },
        dark: true,
        options: {
            customProperties: true
        },
    },
});
