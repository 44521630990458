import store from '@/store';
import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "welcome",
    component: () =>
      import(/* webpackChunkName: "welcome" */ "../views/Login.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/registro",
    name: "registro",
    component: () =>
      import(/* webpackChunkName: "registro" */ "../views/Registro.vue"),
  },
  {
    path: "/instalar",
    name: "instalar",
    component: () =>
      import(/* webpackChunkName: "instalar" */ "../views/Instalar.vue"),
  },
  {
    path: "/home/:alerta?",
    props: true,
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/estadisticas",
    name: "estadisticas",
    component: () =>
      import(
        /* webpackChunkName: "estadisticas" */ "../views/Estadisticas.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/posiciones",
    name: "posiciones",
    component: () =>
      import(/* webpackChunkName: "posiciones" */ "../views/Posiciones.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/material/:id",
    name: "material",
    props: true,
    component: () =>
      import(/* webpackChunkName: "material" */ "../views/Material.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/favoritos",
    name: "favoritos",
    component: () =>
      import(/* webpackChunkName: "favoritos" */ "../views/Favoritos.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/preparate/:id",
    name: "preparate",
    props: true,
    component: () =>
      import(/* webpackChunkName: "preparate" */ "../views/Preparate.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cuestionario/:tipo/:id",
    name: "cuestionario",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "cuestionario" */ "../views/Cuestionario.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/calificacion/:tipo/:puntos/:tiempo/:id/:totalPreguntas",
    name: "calificacion",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "calificacion" */ "../views/Calificacion.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/descargas",
    name: "descargas",
    component: () =>
      import(/* webpackChunkName: "descargas" */ "../views/Descargas.vue"),
  },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const isStandalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    // const isStandalone = true;
    if (!isStandalone && to.name !== "instalar") {
        next({ name: "instalar" });
    } else if (to.matched.some((record) => record.meta.requiresAuth) && !store.state.isAuthenticated) {
        next({ name: "login" });
    } else {
        next();
    }
});

export default router
