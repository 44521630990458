<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-spacer></v-spacer>
            <favorito v-if="!video"></favorito>
            <!-- <v-btn icon x-large color="white" @click="player.currentTime(player.currentTime() -15)">
                <v-icon>mdi-rewind-15</v-icon>
            </v-btn> -->
            <v-btn icon x-large color="secondary" @click="playPause()">
                <v-icon x-large>mdi-play-pause</v-icon>
            </v-btn>
            <!-- <v-btn icon x-large color="white" @click="player.currentTime(player.currentTime() +15)">
                <v-icon>mdi-fast-forward-15</v-icon>
            </v-btn> -->
            <descargar v-if="!video"></descargar>
            <v-spacer></v-spacer>
        </div>
        <video ref="videoPlayer" class="video-js"></video>

        <!-- <div class="d-flex align-center px-5">
            <v-slider v-model="volume" track-color="white" prepend-icon="mdi-volume-high" color="secondary"
                hide-details></v-slider>
            <span>{{ tiempo }}</span>
        </div> -->
    </div>
</template>

<script>
import 'video.js/dist/video-js.css'
import videojs from 'video.js';
import Descargar from './Descargar.vue';
import Favorito from './Favorito.vue';
export default {
    components: { Descargar, Favorito },
    props: {
        video: String,
        default: null
    },
    data() {
        return {
            player: null,
            volume: 80,
            tiempo: '0:00',
            favorito: false,
            options: {
                autoplay: true,
                loop: false,
                controls: true,
                controlBar: {
                    playToggle: false,
                    volumePanel: false,
                    currentTimeDisplay: false,
                    timeDivider: false,
                    durationDisplay: false,
                    remainingTimeDisplay: false,
                    pictureInPictureToggle: false,
                    progressControl: true,
                    fullscreenToggle: true,
                },
                fluid: true,
                sources: [
                    {
                        src: this.video ? this.video : `/video/${this.$store.state.player.archivo}`,
                        type: 'video/mp4'
                    }
                ]
            }
        }
    },
    watch: {
        volume(val) {
            this.player.volume(val / 100);
        }
    },
    methods: {
        playPause() {
            if (!this.player.paused()) {
                this.player.pause(); 
            } else {
                this.player.play(); 
            } 
        },
    },
    mounted() {
        this.player = videojs(this.$refs.videoPlayer, this.options, () => {
            this.player.log('onPlayerReady', this);
        });

        this.player.on('timeupdate', ($tiempo) => {
            this.tiempo = formatTime(this.player.currentTime())
        })

        var formatTime = function (time) {
            return [
                Math.floor((time % 3600) / 60), // minutes
                ('00' + Math.floor(time % 60)).slice(-2) // seconds
            ].join(':');
        };
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    }
}
</script>