import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from "axios";

import "./assets/main.scss";

import mixin from "./mixins";
Vue.mixin(mixin);

Vue.config.productionTip = false

Vue.prototype.$axios = axios;
// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = "https://servidor.podexeltis.com/";
axios.defaults.withCredentials = true;
axios.get("/sanctum/csrf-cookie").then((response) => {});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
