<template>
    <div>
        <v-btn icon x-large color="white" :loading="descargando" @click="descargar()">
            <v-icon>mdi-tray-arrow-down</v-icon>
        </v-btn>
        <v-snackbar v-model="snackbar.visible" centered app color="secondary" elevation="10" rounded timeout="3000">
            <span class="font-weight-bold">{{ snackbar.mensaje }}</span>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    data: () => ({
        snackbar: {
            visible: false,
            mensaje: '',
        },  
        descargando: false,
    }),
    methods: {
        async descargar() {
            this.descargando = true
            let response = await this.addData(this.$store.state.player)
            this.snackbar.visible = true
            if (response == 'existe') {
                this.snackbar.mensaje = 'Ya has descargado este material.'
            } else {
                this.snackbar.mensaje = 'Material descargado exitosamente.'
            }
            this.descargando = false
        },
    }
}
</script>

<style>

</style>