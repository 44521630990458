<template>
    <div class="contenedor" :class="{ 'mini': $store.state.mini }">
        <div v-if="$store.state.mini" class="info darken-1 white--text py-3" @click="maximizar()">
            <v-row no-gutters align="center">
                <v-col cols="2">
                    <v-img src="/img/podcast/imagen-3.jpg" width="40" height="40" class="rounded-lg mx-auto"></v-img>
                </v-col>
                <v-col cols="7">
                    <marquee-text :duration="60" class="font-weight-medium">Lorem ipsum dolor sit amet, consetetur
                        sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna.</marquee-text>
                </v-col>
                <v-col cols="3">
                    <div class="text-center">
                        <v-btn icon color="white" @click.stop="playPause()">
                            <v-icon>mdi-play-pause</v-icon>
                        </v-btn>
                        <v-btn icon color="white" @click.stop="cerrar()">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </div>
        <!-- <v-btn v-if="!$store.state.mini" icon color="white" class="mx-auto d-block" @click="minimizar()">
            <v-icon size="50">mdi-chevron-down</v-icon>
        </v-btn> -->
        <div class="mt-auto" v-show="!$store.state.mini">
            <div class="white--text mt-auto mb-5">
                
                <div class="px-3">
                    <v-alert type="info" dense class="caption rounded-xl" v-if="$store.state.player.leyenda">{{ $store.state.player.leyenda }}</v-alert>
                    <h1 class="font-weight-black font-italic text-h4">{{ $store.state.player.nombre }}</h1>
                    <h2 class="font-weight-medium my-3 text-h6">{{ $store.state.player.autor }}</h2>
                </div>
                <audio-player style="margin-bottom: 120px;" v-if="tipo"></audio-player>
                <video-player style="margin-bottom: 120px;" v-else></video-player>

                <!-- <div class="px-3">
                    <v-btn rounded color="secondary" block class="my-4" :to="{name: 'cuestionario', params: {tipo: 'evaluacion', id: $store.state.player.id}}" :disabled="realizado">{{ realizado ? 'ACTIVIDAD FINALIZADA' : 'INICIAR ACTIVIDAD' }}</v-btn>
                </div> -->
            </div>

            
        </div>
    </div>
</template>

<script>
import MarqueeText from 'vue-marquee-text-component'
import VideoPlayer from './VideoPlayer.vue'
import AudioPlayer from './AudioPlayer.vue'
export default {
    components: { MarqueeText, VideoPlayer, AudioPlayer },
    computed: {
        tipo() {
            if (this.$store.state.player.tipo == 'audio') {
                return true
            }
            return false
        },
        realizado() {
            if (this.$store.state.realizados.find(e => e.id == this.$store.state.player.id)) {
                return true
            }
            return false
        }
    },
    methods: {
        minimizar() {
            this.$router.push({ name: 'home' })
            this.$store.commit("SET_MINI", true)
        },
        maximizar() {
            this.$router.push({ name: 'material' })
            this.$store.commit("SET_MINI", false)
        },
        cerrar() {
            this.wavesurfer.stop()
            this.$store.commit("SET_MINI", null)
        }
    },
}
</script>

<style scoped lang="scss">
.contenedor {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.mini {
        height: initial;
        display: initial;
        position: fixed;
        bottom: 64px;
        width: 100%;
    }
}
</style>