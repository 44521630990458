import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        isAuthenticated: false,
        mini: null,
        token: null,
        consentimiento: true,
        player: null,
        cuestionario: null,
        materiales: [],
        evaluaciones: [],
        realizados: [],
        pendientes: [],
        favoritos: [],
        vistos: [],
    },
    getters: {
        
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user.user
            state.cuestionario = user.cuestionario
            state.materiales = user.materiales
            state.evaluaciones = user.evaluaciones
            state.realizados = user.realizados
            state.pendientes = user.pendientes
            state.favoritos = user.favoritos
            state.vistos = user.vistos
            state.isAuthenticated = true
        },
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_LOGOUT(state) {
            state.user = null;
            state.isAuthenticated = false
            state.mini = null;
            state.token = null;
            state.consentimiento = true;
        },
        SET_PUNTOS(state, puntos) {
            if (puntos.tipo == 'evaluacion') {
                if (puntos.puntos == 10) {
                    let index = state.pendientes.findIndex(material => material.id == puntos.id);
                    state.realizados.push(state.player)
                    state.pendientes.splice(index, 1);
                }
                state.player = null
            } else {
                state.user.puntos += parseFloat(puntos.puntos);
            }
        },
        SET_VISTO(state, id) {
            let index = state.vistos.findIndex(id => id == id);
            console.log(index);
            if (index) {
                console.log('visto');
                state.vistos.push(id)
            }
        },
        SET_FAVORITO(state, favorito) {
            if (favorito) {
                let index = state.favoritos.findIndex(material => material.id == state.player.id);
                state.favoritos.splice(index, 1);
            } else {
                state.favoritos.push(state.player)
            }
        },
        SET_MINI(state, val) {
            state.mini = val
        },
        SET_CONSENTIMIENTO(state) {
            state.consentimiento = false
        },
        SET_PLAYER(state, id) {
            state.player = state.materiales.find(material => material.id == id)
        }
    },
    actions: {
        async registro ({ commit }, credenciales) {
            try {
                let response = await axios.post("/register", credenciales);
                commit("SET_USER", response.data.user);
                commit("SET_TOKEN", response.data.token);
                localStorage.setItem("token", response.data.token);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async login ({ commit }, credenciales) {
            try {
                let response = await axios.post("/login", credenciales);
                commit("SET_USER", response.data);
                commit("SET_TOKEN", response.data.token);
                localStorage.setItem("token", response.data.token);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async getUser ({ commit }, token) {
            try {
                let response = await axios.get("/api/user", {
                    headers: { Authorization: `Bearer ${token}` }
                });
                commit("SET_USER", response.data);
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
        async logout ({ commit, state }) {
            try {
                let response = await axios.post("/logout", {}, {
                    headers: { Authorization: `Bearer ${state.token}` }
                });
                commit("SET_LOGOUT", response.data);
                localStorage.removeItem("token");
                return { success: true };
            } catch (error) {
                console.error(error)
                return { success: false, message: error.response.data.message };
            }
        },
    },
    modules: {
    }
})
