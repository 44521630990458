<template>
    <v-btn icon x-large color="white" @click="guardar()" :loading="guardando">
        <v-icon>mdi-heart{{ favorito ? '' : '-outline' }}</v-icon>
    </v-btn>
</template>

<script>
export default {
    data:() => ({
        favorito: false,
        guardando: false,
    }),
    methods: {
        async guardar() {
            this.guardando = true
            await this.$axios.post("/api/favorito", {
                material_id: this.$store.state.player.id,
            }, {
                headers: { Authorization: `Bearer ${this.$store.state.token}` }
            })
            .then(response => {
                this.$store.commit('SET_FAVORITO', this.favorito);
                this.favorito = !this.favorito
            })
            .catch(error => console.error(error))
            this.guardando = false
        }
    },
    mounted() {
        if (this.$store.state.favoritos.find(e => e.id == this.$store.state.player.id)) {
            this.favorito = true
        }
    }
}
</script>

<style>

</style>